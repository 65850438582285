import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueCurrencyInput from 'vue-currency-input'

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'USD' }
}
Vue.use(VueCurrencyInput, pluginOptions)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

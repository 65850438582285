import Vue from 'vue'
import api from '@/api'

const initial_state = {
    active_id: null,
    active_list: [],
    collection: {},
}

const state = { ...initial_state }

const getters = {
    activeProductId(state) {
        return state.active_id
    },
    activeProduct(_, getters) {
        return getters.formattedProducts[getters.activeProductId]
    },
    activeProductList(state, getters) {
        return state.active_list.map((id) => getters.formattedProducts[id])
    },
    formattedProducts(state, getters, rootState, rootGetters) {
        const formatted_product = {}
        Object.values(state.collection).forEach((product) => {
            formatted_product[product.id] = {
                ...product,
                formatted: true,
                amount_cents: product.cost * 100,
                price_cents: product.price * 100,
            }
        })
        return formatted_product
    },
}

const actions = {
    updateProductPriceRule({ commit }, payload) {
        return api.post(`/product/price_rule`, payload).then(({ data }) => {
            console.log(data.product)
            commit('setProducts', [data.product])
        })
    },
    updatePriceRules({ commit }, target) {
        return api.post(`/product/update/${target}`)
    },
    getProducts({ commit, rootGetters }, options = {}) {
        let limit = options.limit ? options.limit : 5
        let url = `/products?limit=${limit}`
        if (options.keyword) {
            url = url + `&keyword=${options.keyword}`
        }
        return api.get(url).then(({ data }) => {
            commit('setProducts', data.products)
            commit(
                'setActiveList',
                data.products.map((p) => p.id)
            )
        })
    },
    setProducts({ commit }, products) {
        commit('setProducts', products)
    },
    setActiveProduct({ commit }, product_id) {
        commit('setActiveProduct', product_id)
    },
}

const mutations = {
    setActiveProduct: (state, id) => {
        state.active_id = id
    },
    setActiveList: (state, ids) => {
        state.active_list = ids
    },
    setProducts(state, products) {
        const new_product = {}
        for (var i = 0; i < products.length; i++) {
            new_product[products[i].id] = products[i]
        }
        state.collection = { ...state.collection, ...new_product }
    },
    deleteProduct(state, id) {
        Vue.delete(state.collection, id)
    },
}

export default { namespaced: true, state, getters, actions, mutations }

import Cookies from 'js-cookie'
import api from '@/api'

const COOKIE_DOMAIN = `${process.env.VUE_APP_DOMAIN}`

const actions = {
    setUser({ dispatch }, user) {
        dispatch('user/setUser', user, { root: true })
    },
    setLoggedIn({ dispatch }, logged_in) {
        dispatch('user/setLoggedIn', logged_in, { root: true })
    },
    setIsFreshToken({ dispatch }, is_fresh_token) {
        dispatch('user/setIsFreshToken', is_fresh_token, { root: true })
    },
    logout({ dispatch }) {
        api.post('/logout')
        dispatch('setLoggedIn', false)
        Cookies.remove('_trat', { domain: COOKIE_DOMAIN, path: '' })
        Cookies.remove('_trrt', { domain: COOKIE_DOMAIN, path: '' })
        api.removeAuthorizationHeader()
    },
    async login({ dispatch, commit }, payload) {
        return api.post('/login', payload).then(({ data }) => {
            dispatch('setAccessToken', data.access_token)
            dispatch('setLoggedIn', true)
            dispatch('setIsFreshToken', true)
            dispatch('setUser', data.user)
            dispatch('setRefreshToken', data.refresh_token)
            return data.user
        })
    },
    register({ dispatch, commit }, payload) {
        return api.post('/register', payload)
    },
    cookie() {
        const c = Cookies.get('_trat', { domain: COOKIE_DOMAIN, path: '' })
        if (c) {
            api.setAuthorizationHeader(c)
            return true
        }
        return false
    },
    async getUser({ dispatch }) {
        await api.get('/user').then(({ data }) => {
            dispatch('setLoggedIn', true)
            dispatch('setUser', data)
        })
    },
    async refresh({ dispatch }) {
        const refresh_token = Cookies.get('_trrt', {
            domain: COOKIE_DOMAIN,
            path: '',
        })
        api.setAuthorizationHeader(refresh_token)
        let new_token = await api
            .post('/refresh')
            .then(({ data }) => {
                dispatch('setAccessToken', data.access_token)
                dispatch('setIsFreshToken', false)
                return data
            })
            //page got stuck on 422 code / 'Not enough or too many segments' error
            // log out to clear cookies
            .catch(err => dispatch('logout'))
        return new_token
    },
    async setAccessToken({}, access_token) {
        await api.setAuthorizationHeader(access_token)
        Cookies.set('_trat', access_token, {
            expires: 30,
            domain: COOKIE_DOMAIN,
            path: '',
        })
    },
    setRefreshToken({}, refresh_token) {
        Cookies.set('_trrt', refresh_token, {
            expires: 30,
            domain: COOKIE_DOMAIN,
            path: '',
        })
    },
}

export default {
    namespaced: true,
    getters: {},
    actions,
    mutations: {},
    state: {},
}

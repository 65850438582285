import axios from 'axios'
import store from '@/store'

const API = process.env.VUE_APP_API_URL

const headers = {
    'Content-Type': 'application/json',
}

var api = {}

api.setAuthorizationHeader = function(token) {
    headers['Authorization'] = 'Bearer ' + token
}

api.removeAuthorizationHeader = function() {
    delete headers['Authorization']
}

axios.interceptors.response.use(
    response => response,
    async error => {
        const status = error.response ? error.response.status : null
        if (
            status === 401 &&
            !['/login', '/refresh', '/logout'].some(url_part =>
                error.config.url.includes(url_part)
            )
        ) {
            return store.dispatch('auth/refresh').then(_ => {
                return axios.request({ ...error.config, ...{ headers } })
            })
        }
        return Promise.reject(error)
    }
)

api.get = (url, config) => axios.get(API + url, { ...config, headers })
api.post = (url, data, config) =>
    axios.post(API + url, data, { ...config, headers })
api.patch = (url, data, config) =>
    axios.patch(API + url, data, { ...config, headers })
api.delete = (url, config) => axios.delete(API + url, { ...config, headers })

export default api

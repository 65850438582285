import Vue from 'vue'
import api from '@/api'

import { METAL_PRICE_BASE } from '../constants/vendors'

const initial_state = {
    metalPriceLast: { ...METAL_PRICE_BASE },
    metalPriceCurrent: { ...METAL_PRICE_BASE },
}

const state = { ...initial_state }

const getters = {
    metalPriceLast(state) {
        return state.metalPriceLast
    },
    metalPriceCurrent(state) {
        return state.metalPriceCurrent
    },
}

const actions = {
    getPricing({ dispatch }) {
        dispatch('getMetalPricingLatest')
        dispatch('getMetalPricingLast')
    },
    getMetalPricingLatest({ commit }) {
        return api.get(`/vendor/metalprice/latest`).then(({ data }) => {
            console.log(data)
            commit('setMetalPricingLatest', data.pricing)
        })
    },
    //retrieving from last event, even though pricing will be set on the product
    getMetalPricingLast({ commit }) {
        return api.get(`/vendor/metalprice/last`).then(({ data }) => {
            console.log(data)
            if (data.pricing && data.pricing.length) {
                commit('setMetalPricingLast', data.pricing)
            }
        })
    },
}

const mutations = {
    setMetalPricingLatest: (state, pricing) => {
        state.metalPriceCurrent = pricing
    },
    setMetalPricingLast: (state, pricing) => {
        state.metalPriceLast = pricing
    },
}

export default { namespaced: true, state, getters, actions, mutations }

<template>
  <div id="app" class="bg-gray-100">
      <router-view />
  </div>
</template>
<script>
import './styles/portal.css'
export default {
    name: 'App',
}
</script>
<style>
body {
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
}

#app {
    /* font-family: 'Signika', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
}
</style>

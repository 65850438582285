import Vue from 'vue'
import api from '@/api'

const PRICE_RULE_TYPE_DEFAULT = 'default'

const initial_state = {
    active_id: null,
    collection: {},
    targets: [],
}

const state = { ...initial_state }

const getters = {
    activePriceRuleId(state) {
        return state.active_id
    },
    targetOptions() {
        return state.targets
    },
    activePriceRule(_, getters) {
        return getters.formattedPriceRules[getters.activePriceRuleId]
    },
    priceRulesDefault(state, getters, rootState, rootGetters) {
        return Object.values(state.collection).filter(
            (priceRule) => priceRule.type_ == PRICE_RULE_TYPE_DEFAULT
        )
    },
    priceRulesDefaultByTarget: (state, getters, rootState, rootGetters) => (
        target_id
    ) => {
        return Object.values(state.collection).filter(
            (priceRule) =>
                priceRule.type_ == PRICE_RULE_TYPE_DEFAULT &&
                priceRule.target === target_id
        )
    },
    formattedPriceRules(state, getters, rootState, rootGetters) {
        const formatted_priceRule = {}
        Object.values(state.collection).forEach((priceRule) => {
            formatted_priceRule[priceRule.id] = {
                ...priceRule,
                formatted: true,
                threshold_display: `$${priceRule.min_threshold}`,
            }
        })
        return formatted_priceRule
    },
}

const actions = {
    createPriceRules({ commit }, payload) {
        return api.post(`/pricerules`, payload).then(({ data }) => {
            payload.delete.forEach((deleted) =>
                commit('deletePriceRule', deleted.id)
            )
            payload.update.forEach((updated) =>
                commit('deletePriceRule', updated.id)
            )
            commit('setPriceRules', data.price_rules)
        })
    },
    getDefaultPriceRules({ commit, rootGetters }) {
        return api.get(`/pricerules`).then(({ data }) => {
            commit('setPriceRules', data.price_rules)
            commit('setTargets', data.targets)
        })
    },
    setPriceRules({ commit }, priceRules) {
        commit('setPriceRules', priceRules)
    },
}

const mutations = {
    setActivePriceRule: (state, id) => {
        state.active_id = id
    },
    setPriceRules(state, priceRules) {
        const new_priceRule = {}
        for (var i = 0; i < priceRules.length; i++) {
            new_priceRule[priceRules[i].id] = priceRules[i]
        }
        state.collection = { ...state.collection, ...new_priceRule }
    },
    setTargets(state, targets) {
        state.targets = targets
    },
    deletePriceRule(state, id) {
        Vue.delete(state.collection, id)
    },
}

export default { namespaced: true, state, getters, actions, mutations }

const initial_state = {
    logged_in: false,
    isFreshToken: false,
}

const state = { ...initial_state }

const getters = {
    user(state) {
        return state
    },

}

const actions = {
    setUser({ commit, dispatch }, user) {
        commit('setUser', user)

    },
    setLoggedIn({ commit }, logged_in) {
        commit('setLoggedIn', logged_in)
    },
    setIsFreshToken({ commit }, is_fresh_token) {
        commit('setIsFreshToken', is_fresh_token)
    },
}

const mutations = {
    setUser: (state, user) => {
        Object.assign(state, user)
    },
    setLoggedIn: (state, logged_in) => {
        state.logged_in = logged_in
    },
    setIsFreshToken: (state, isFreshToken) => {
        state.isFreshToken = isFreshToken
    },

}

export default { namespaced: true, state, getters, actions, mutations }

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
        {
            path: '/login',
            name: 'login',
            component: () =>
                import(/* webpackChunkName: "Login" */ '@/views/Login'),
            meta: {
                auth: false,
                title: 'Login',
            },
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import(/* webpackChunkName: "Login" */ '@/views/Register'),
            meta: {
                auth: false,
                title: 'User Registration',
            },
        },
        {
            path: '/logout',
            name: 'logout',
            meta: {
                auth: true,
            },
            beforeEnter(to, from, next) {
                store.dispatch('auth/logout')
                return next({ name: 'login' })
            },
        },
        {
            path: '/',
            name: 'dashboard',
            component: () =>
                import(
                    /* webpackChunkName: "Dashboard" */ '@/views/TheDashboard'
                ),
            meta: {
                auth: true,
                title: 'Dashboard',
            },
            children: [
                {
                    path: '/home',
                    name: 'home',
                    component: () =>
                        import(
                            /* webpackChunkName: "Content" */ '@/views/Home'
                        ),
                    meta: {
                        auth: true,
                        title: 'Home',
                    },
                },
            ]
          }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
    // add document titles from meta (a11y)
    document.title = to.meta.title

    // just always allow logout state to be reached
    if (to.name === 'logout') {
        return next()
    }

    let hasAccessTokenCookie

    await store.dispatch('auth/cookie').then(bool => {
        hasAccessTokenCookie = bool
    })

    if (!hasAccessTokenCookie) {

      // bump to login if auth route, otherwise resolve
      return to.meta.auth ? next({ name: 'login' }) : next()
    }

    if (!store.state.user.logged_in) {
        // if auth is available, pull user
        await store.dispatch('auth/getUser')
    }
    //if logging in from cookie failed, go to log in
    if (!store.state.user.logged_in) {
        // bump to login if auth route, otherwise resolve
        return to.meta.auth ? next({ name: 'login' }) : next()
    }
    // solidify route
    return next()
})

export default router

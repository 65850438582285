import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import price_rule from './price_rule'
import product from './product'
import user from './user'
import vendor from './vendor'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        product,
        price_rule,
        user,
        vendor,
    },
})

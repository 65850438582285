export const METAL_PRICE_BASE = {
    gold: 0,
    silver: 0,
    platinum: 0,
    palladium: 0,
}

export const CONVERSIONS = {
    ounce: {
        dwt: 0.054857243190857,
    },
    gold: {
        '14k': (14 / 24) * 0.999,
        '18k': (18 / 24) * 0.999,
    },
}
